import { Ga4Event, Subscription, UserPlan } from '@/constants/enum';
import { apiCaller } from '@/redux/query';
import sessionSlice, { gaFourSelector } from '@/redux/slice/session.slice';
import { useDispatch, useSelector } from 'react-redux';

const configSession = [
  {
    value: Ga4Event.CheckoutRulesClick,
    key: 'checkoutRules',
  },
  {
    value: Ga4Event.SynctrackBannerClick,
    key: 'synctrack',
  },
  {
    value: Ga4Event.ReturnClick,
    key: 'return',
  },
  {
    value: Ga4Event.ClickOT,
    key: 'ot',
  },
  {
    value: Ga4Event.ClickEstimated,
    key: 'estimated',
  },
  {
    value: Ga4Event.ClickCookies,
    key: 'cookies',
  },
  {
    value: Ga4Event.ClickFacebookPixel,
    key: 'fbPixel',
  },
  {
    value: Ga4Event.ClickTrustify,
    key: 'trustify',
  },
  {
    value: Ga4Event.PricingPlan,
    key: 'pricingPlan',
  },
  {
    value: Ga4Event.GeneralSetting,
    key: 'generalSetting',
  },
  {
    value: Ga4Event.CheckoutRulesClick,
    key: 'checkoutRules',
  },
  {
    value: Ga4Event.ImpressAdoric,
    key: Ga4Event.ImpressAdoric,
  },
  {
    value: Ga4Event.ImpressFacebookPixel,
    key: Ga4Event.ImpressFacebookPixel,
  },
  {
    value: Ga4Event.ImpressTrustify,
    key: Ga4Event.ImpressTrustify,
  },
];

export const useGa4 = () => {
  const dispatch = useDispatch();
  const gaFour = useSelector(gaFourSelector);

  const [fetchGA] = apiCaller.useFetchGAMutation();

  const handleGa4 = (
    event: Ga4Event,
    params?: {
      plan: UserPlan;
      type: Subscription;
    },
  ) => {
    fetchGA({
      eventName: event,
      params,
    });
    const gaFourSelected = configSession.find((item) => item.value === event);
    if (gaFourSelected) {
      dispatch(
        sessionSlice.actions.handleGaFour({
          key: gaFourSelected.key as keyof typeof gaFour,
        }),
      );
    }
  };
  return {
    handleGa4,
  };
};
