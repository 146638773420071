import EmptyState from '@/components/EmptyState';
import RegularText from '@/components/RegularText';
import TooltipUpdateHigherPlan from '@/components/TooltipUpgradePlan/TooltipUpdateHigherPlan';
import { config } from '@/config';
import { ActionType, CriteriaType, IsVpn, ListType, OrderRisk } from '@/constants/enum';
import { dateToTimeStamp, formatDate } from '@/helpers';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import fraudOrdersSlice, { fraudOrdersTableSelector } from '@/redux/slice/fraudOrders';
import {
  Badge,
  BadgeProps,
  Banner,
  Button,
  Icon,
  IndexTable,
  InlineStack,
  Pagination,
  SkeletonBodyText,
  Tooltip,
} from '@shopify/polaris';
import { InfoIcon } from '@shopify/polaris-icons';
import { memo, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const badgeRiskConfig: Array<{ score: OrderRisk; tone: BadgeProps['tone']; content: string }> = [
  {
    score: OrderRisk.Low,
    tone: 'success',
    content: 'Low risk',
  },
  {
    score: OrderRisk.Medium,
    tone: 'warning',
    content: 'Medium risk',
  },
  {
    score: OrderRisk.High,
    tone: 'critical',
    content: 'High risk',
  },
];

const fakeData = {
  data: [
    {
      id: 1,
      name: 'Order #1',
      createdAt: 1706373837.21,
      highestRiskScore: '0.6',
      isVpn: 'No',
      browserIp: '192.16*.1.***',
      riskLevel: 'medium',
      blocked: false,
    },
    {
      id: 2,
      name: 'Order #2',
      createdAt: 1704124619.62,
      highestRiskScore: '0.2',
      isVpn: 'No',
      browserIp: '888.16*.1.***',
      riskLevel: 'low',
      blocked: false,
    },
    {
      id: 3,
      name: 'Order #3',
      createdAt: 1708508777.741,
      highestRiskScore: '0.6',
      isVpn: 'Yes',
      browserIp: '876.23*.1.***',
      riskLevel: 'medium',
      blocked: false,
    },
    {
      id: 4,
      name: 'Order #4',
      createdAt: 1705414532.946,
      highestRiskScore: '0.7',
      isVpn: 'No',
      browserIp: '938.34*.1.***',
      riskLevel: 'high',
      blocked: false,
    },
    {
      id: 5,
      name: 'Order #5',
      createdAt: 1710411191.151,
      highestRiskScore: '0.1',
      isVpn: 'Yes',
      browserIp: '425.23*.1.***',
      riskLevel: 'low',
      blocked: false,
    },
    {
      id: 6,
      name: 'Order #6',
      createdAt: 1713690333.785,
      highestRiskScore: '0.7',
      isVpn: 'Yes',
      browserIp: '342.23*.1.***',
      riskLevel: 'high',
      blocked: false,
    },
    {
      id: 7,
      name: 'Order #7',
      createdAt: 1713793887.286,
      highestRiskScore: '0.1',
      isVpn: 'No',
      browserIp: '430.30*.1.***',
      riskLevel: 'low',
      blocked: false,
    },
    {
      id: 8,
      name: 'Order #8',
      createdAt: 1714400469.583,
      highestRiskScore: '0.2',
      isVpn: 'Yes',
      browserIp: '847.98*.1.***',
      riskLevel: 'low',
      blocked: false,
    },
    {
      id: 9,
      name: 'Order #9',
      createdAt: 1714956377.246,
      highestRiskScore: '0.9',
      isVpn: 'No',
      browserIp: '342.34*.1.***',
      riskLevel: 'high',
      blocked: false,
    },
    {
      id: 10,
      name: 'Order #10',
      createdAt: 1711890906.04,
      highestRiskScore: '0.5',
      isVpn: 'No',
      browserIp: '192.23*.1.***',
      riskLevel: 'medium',
      blocked: false,
    },
  ],
  meta: {
    totalResult: 10,
    currentPage: 1,
    perPage: 10,
  },
};

const resourceName = {
  singular: 'visitor',
  plural: 'visitors',
};

interface Props {
  isOpenShowDemo: boolean;
}

const FraudOrdersTable: React.FC<Props> = ({ isOpenShowDemo }): JSX.Element => {
  const dispatch = useDispatch();
  const fraudOrders = useSelector(fraudOrdersTableSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const { userPlanFree, shopifyPlanPlus } = userPlans();
  const contentTooltip =
    userPlanFree && shopifyPlanPlus
      ? 'Upgrade to the Shopify Plus plan or above to get more information'
      : 'Upgrade to the Premium plan or above to get more information';

  const [addBlackList, addBlackListStatus] = apiCaller.useUpsertRuleMutation();
  const ruleBlockSummary = apiCaller.useRulesSummaryQuery({
    type: ActionType.Block,
    priority: ListType.BlackList,
    criteria: CriteriaType.IpAddress,
  }, { skip: isSkip });

  const { data, isLoading, isFetching } = apiCaller.useGetListOrderQuery({
    endDate: dateToTimeStamp(fraudOrders.endDate),
    startDate: dateToTimeStamp(fraudOrders.startDate),
    ip: fraudOrders.search,
    isVpn: fraudOrders.vpn,
    page: fraudOrders.page,
    perPage: Number(fraudOrders.perPage),
    sortBy: fraudOrders.sortBy,
    sortDirection: fraudOrders.sort.toUpperCase(),
    riskLevel: fraudOrders.risk,
  }, { skip: isSkip });

  const [idSelected, setIdSelected] = useState(-1);

  //eslint-disable-next-line react-hooks/exhaustive-deps
  const dataSettings = useSelector(dataSettingsSelector);
  const maxLimitRules = dataSettings?.settings.user.numberRuleLimit || 0;
  const countNumberOfRule = ruleBlockSummary.data ? maxLimitRules - ruleBlockSummary.data.totalRulesActivated : 0;
  const dataTableOrder = isOpenShowDemo ? fakeData : data;

  const items = useMemo(() => {
    return dataTableOrder?.data.map((item) => {
      const riskScore = item.highestRiskScore;

      const badgeRisk = riskScore === null ? undefined : badgeRiskConfig.find((badgeItem) => badgeItem.score === item.riskLevel);

      return {
        id: item.id,
        orderId: (
          <Button
            key={item.id}
            variant="plain"
            external
            disabled={isOpenShowDemo}
            onClick={() => {
              window.open('https://' + config.shop + '/admin/orders/' + item.id, '_blank');
            }}
          >
            {item.name}
          </Button>
        ),
        orderTime: <RegularText>{formatDate(item.createdAt)}</RegularText>,
        score: <Badge tone={badgeRisk?.tone}>{item.highestRiskScore.toString()}</Badge>,
        vpn:
          userPlanFree && !isOpenShowDemo ? (
            <RegularText>---</RegularText>
          ) : (
            <Badge tone={item.isVpn === IsVpn.Yes ? 'critical' : 'success'}>{item.isVpn}</Badge>
          ),
        ipAddress: <RegularText>{item.browserIp}</RegularText>,
        orderType: <Badge tone={badgeRisk?.tone}>{badgeRisk?.content}</Badge>,
        action: (
          <InlineStack gap="200">
            <>
              {userPlanFree ? (
                countNumberOfRule > 0 ? (
                  <Button
                    disabled={item.blocked || isOpenShowDemo}
                    loading={addBlackListStatus.isLoading && idSelected === item.id}
                    onClick={() => {
                      setIdSelected(item.id);
                      addBlackList({
                        criteria: CriteriaType.IpAddress,
                        priority: ListType.BlackList,
                        type: ActionType.Block,
                        ipAddress: item.browserIp,
                        isActive: true,
                      }).then(() => {
                        setIdSelected(-1);
                      });
                    }}
                  >
                    {item.blocked ? 'Blocked' : 'Block IP'}
                  </Button>
                ) : (
                  <Tooltip content="Available on higher plan">
                    <Button disabled={true}>Block IP</Button>
                  </Tooltip>
                )
              ) : (
                <Button
                  disabled={item.blocked || isOpenShowDemo}
                  loading={addBlackListStatus.isLoading && idSelected === item.id}
                  onClick={() => {
                    setIdSelected(item.id);
                    addBlackList({
                      criteria: CriteriaType.IpAddress,
                      priority: ListType.BlackList,
                      type: ActionType.Block,
                      ipAddress: item.browserIp,
                      isActive: true,
                    }).then(() => {
                      setIdSelected(-1);
                    });
                  }}
                >
                  {item.blocked && item.browserIp ? 'Blocked' : 'Block IP'}
                </Button>
              )}
            </>

            <Button
              disabled={isOpenShowDemo}
              onClick={() => window.open('https://' + config.shop + '/admin/orders/' + item.id, '_blank')}
            >
              Cancel order
            </Button>
          </InlineStack>
        ),
      };
    });
  }, [
    addBlackList,
    addBlackListStatus.isLoading,
    countNumberOfRule,
    dataTableOrder?.data,
    idSelected,
    isOpenShowDemo,
    userPlanFree,
  ]);

  const rowMarkup = useMemo(() => {
    return items?.map(({ id, orderId, orderTime, score, vpn, ipAddress, orderType, action }, index) => (
      <IndexTable.Row id={id.toString()} key={id} position={index}>
        <IndexTable.Cell>{orderId}</IndexTable.Cell>
        <IndexTable.Cell>{orderTime}</IndexTable.Cell>
        <IndexTable.Cell>{score}</IndexTable.Cell>
        <IndexTable.Cell>{vpn}</IndexTable.Cell>
        <IndexTable.Cell>{ipAddress}</IndexTable.Cell>
        <IndexTable.Cell>{orderType}</IndexTable.Cell>
        <IndexTable.Cell>{action}</IndexTable.Cell>
      </IndexTable.Row>
    ));
  }, [items]);

  return (
    <div className="dashboard-table-container">
      {isOpenShowDemo ? (
        <div className="mt-8 mb-8">
          <Banner tone="warning">This is only a demo data of Fraud Order Analytics, not the actual data of any stores.</Banner>
        </div>
      ) : null}
      <IndexTable
        resourceName={resourceName}
        itemCount={items?.length || 0}
        emptyState={isLoading ? <SkeletonBodyText lines={20} /> : <EmptyState />}
        headings={[
          { title: <RegularText>Order</RegularText>, id: '1' },
          { title: <RegularText>Created time</RegularText>, id: '2' },
          {
            title: (
              <div className="d-flex fraud-score">
                <RegularText>Fraud order score</RegularText>
                <div className="pointer">
                  <Tooltip content="A score from 0 to 1 is given to the order. The higher the score (closer to 1), the more likely the order is fraudulent. These data are provided by Shopify.">
                    <Icon source={InfoIcon} tone="subdued" />
                  </Tooltip>
                </div>
              </div>
            ),
            id: '3',
          },
          {
            title: userPlanFree ? (
              <div className="d-flex">
                <RegularText>Order from VPN/Proxy</RegularText>
                <div className="ml-4">
                  <TooltipUpdateHigherPlan tone="base" content={contentTooltip} />
                </div>
              </div>
            ) : (
              <RegularText>Order from VPN/Proxy</RegularText>
            ),
            id: '4',
          },
          {
            title: <RegularText>IP address</RegularText>,
            id: '5',
          },
          {
            title: <RegularText>Order type</RegularText>,
            id: '6',
          },
          {
            title: <RegularText>Action</RegularText>,
            id: '7',
          },
        ]}
        selectable={false}
      >
        {isLoading ? <SkeletonBodyText lines={20} /> : rowMarkup}
      </IndexTable>
      <div className="mt-16 mb-16 table-pagination">
        {data && data?.meta.totalResult > 0 ? (
          <Pagination
            label={
              data?.meta.totalResult > 0
                ? `Showing ${(data?.meta.currentPage - 1) * Number(data?.meta.perPage) + 1} to ${Math.min(
                  fraudOrders.page * Number(data?.meta.perPage),
                  data?.meta.totalResult,
                )} of ${data?.meta.totalResult} orders`
                : null
            }
            hasPrevious={data && data?.meta?.currentPage > 1 && !isFetching}
            onPrevious={() => {
              dispatch(
                fraudOrdersSlice.actions.handleFraudOrdersTable({
                  ...fraudOrders,
                  page: fraudOrders.page - 1,
                }),
              );
            }}
            hasNext={
              data &&
              data?.meta?.currentPage < Math.ceil((data?.meta?.totalResult ?? 0) / Number(data?.meta.perPage)) &&
              !isFetching
            }
            onNext={() => {
              dispatch(
                fraudOrdersSlice.actions.handleFraudOrdersTable({
                  ...fraudOrders,
                  page: fraudOrders.page + 1,
                }),
              );
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default memo(FraudOrdersTable);
