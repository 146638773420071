/* eslint-disable jsx-a11y/img-redundant-alt */
import { images } from '@/asset';
import RegularText from '@/components/RegularText';
import { config } from '@/config';
import { link } from '@/constants/link';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { Button, Collapsible, Icon, Link, Text } from '@shopify/polaris';
import { AlertTriangleIcon } from '@shopify/polaris-icons';
import { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const StepThree = () => {
  const [checkStatus, setCheckStatus] = useState(true);
  const isSkip = useSelector(isSkipApiSelector);
  const [checkEmbed] = apiCaller.useLazyCheckAppEmbedStatusQuery();
  const handleOpenTheme = useCallback(() => {
    window.open(link.isOpenAppEmbedUrl, '_blank');
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      checkEmbed().then((res) => {
        try {
          if ('data' in res) setCheckStatus(res.data?.status || false);
        } catch (err) {
          console.log(err);
        }
      });
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { data } = apiCaller.useGetGeneralDetailQuery(config.shop, { skip: isSkip });
  const handleOpenTestBlock = () => {
    window.open(data?.settings.testBlockUrl);
  };
  return (
    <div className="step-three">
      <div className="welcome-title">
        <Text as="h3" variant="headingXl">
          Let’s see how Blockify works
        </Text>
      </div>
      <div className="step-three-content">
        <img src={images.step3Image} alt="img" />
        <Collapsible open={!checkStatus} id="basic-collapsible" transition={{ duration: '500ms' }} expandOnPrint>
          <div className="banner-warning">
            <Icon source={AlertTriangleIcon} tone="warning" />
            <RegularText>
              For Blockify works seamlessly with your theme, please make sure to turn on the app in the{' '}
              <Link removeUnderline external onClick={handleOpenTheme}>
                Theme Editor
              </Link>
              .
            </RegularText>
          </div>
        </Collapsible>

        <div className="mt-16">
          <RegularText>
            Try a demo blocking test with your IP and your store to see how Blockify works.{' '}
            <Button variant="plain" onClick={handleOpenTestBlock} removeUnderline>
              Test now
            </Button>
          </RegularText>
        </div>
      </div>
    </div>
  );
};

export default memo(StepThree);
