import { dashboardImage } from '@/asset/images/dashboard';
import promote from '@/asset/images/promote.png';
import BannerLimitRules from '@/components/BannerLimitRules';
import BannerReviewApp from '@/components/BannerReviewApp';
import BannerTemplate from '@/components/BannerTemplate';
import BoldText from '@/components/BoldText';
import FlashSaleCard from '@/components/FlashSaleCard';
import CustomLayout from '@/components/layout';
import RegularText from '@/components/RegularText';
import { config } from '@/config';
import { Ga4Event } from '@/constants/enum';
import { link } from '@/constants/link';
import { useGa4 } from '@/hooks/useGa4';
import UserPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import bannerSlice, {
  bannerNoticeEmbeddedAppSelector,
  bannerNoticeFreePlanSelector,
  showFlashSaleCardSelector,
} from '@/redux/slice/banner.slice';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import sessionSlice, { gaFourSelector } from '@/redux/slice/session.slice';
import { Banner, Button, Card, Collapsible, Icon, InlineGrid, Text } from '@shopify/polaris';
import { BookIcon, ChatIcon, GlobeAsiaFilledIcon } from '@shopify/polaris-icons';
import dayjs from 'dayjs';
import mixpanel from 'mixpanel-browser';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Overview from './overview';
import RecommendedApps from './recommendedApps';
import { HomePageStyled } from './styled';

function HomePage() {
  const recommendAppRef = useRef(null);
  const dispatch = useDispatch();
  const { userPlanFree, userPlanEnterprise, shopifyPlanPlus, planAppliedList } = UserPlans();
  const bannerNoticeEmbeddedApp = useSelector(bannerNoticeEmbeddedAppSelector);
  const bannerNoticeFreePlan = useSelector(bannerNoticeFreePlanSelector);
  const showFlashSaleCard = useSelector(showFlashSaleCardSelector);
  const dataSettings = useSelector(dataSettingsSelector);
  const { handleGa4 } = useGa4();
  const gaFour = useSelector(gaFourSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const checkEmbeddedStatus = apiCaller.useCheckAppEmbedStatusQuery(undefined, { skip: isSkip });
  const [trackAction] = apiCaller.useTrackActionMutation();
  const [showBannerFromToDate, setShowBannerFromToDate] = useState(true);
  const isShowBannerCampaign =
    !userPlanEnterprise && !shopifyPlanPlus && showFlashSaleCard && showBannerFromToDate && !planAppliedList?.length;

  useEffect(() => {
    const currentDate = dayjs();
    const campaignStartDate = dayjs(1722445199000); //2024-07-31T23:59:59+07:00
    const campaignEndDate = dayjs(1726851599999); //2024-09-20T23:59:59+07:00
    const lastInstalledDate = dayjs(dataSettings?.settings.user.lastInstalledDate || dataSettings?.settings.user.installedDate);

    if (lastInstalledDate.isBefore(campaignStartDate) && currentDate.isBefore(campaignEndDate)) {
      setShowBannerFromToDate(true);
    } else {
      setShowBannerFromToDate(false);
    }
  }, [dataSettings?.settings.user.installedDate, dataSettings?.settings.user.lastInstalledDate]);

  const infoAndSupport = useMemo(() => {
    return [
      {
        title: 'Contact support (24/7)',
        description: 'Get in touch with the support team. The response time on live chat under a few hours.',
        onAction: () => {
          const sendText = 'I need assistance with using Blockify';
          try {
            $crisp.push(['set', 'session:event', ['ReviewStoreBlockify']]);
            $crisp.push(['do', 'chat:open']);
            $crisp.push(['do', 'message:send', ['text', sendText]]);
          } catch (err) {
            console.log(err);
          }
        },
        btnContent: 'Chat with us',
        icon: ChatIcon,
      },
      {
        title: 'Read user guideline',
        description: 'Step-by-step instruction articles to guide you in setting up rules in the easiest way.',
        onAction: () => {
          window.open('https://docs.ipblocker.io/');
        },
        btnContent: 'Read user guideline',
        icon: BookIcon,
      },
      {
        title: 'Discover our website',
        description: 'Explore our website to find the new updates of our brand.',
        onAction: () => {
          window.open('https://www.ipblocker.io/');
        },
        btnContent: 'Visit website',
        icon: GlobeAsiaFilledIcon,
      },
    ];
  }, []);

  const handleLearnMoreSynctrack = () => {
    trackAction('read_docs');
    window.open('https://apps.shopify.com/synctrack?utm_campaign=crs&utm_source=blockify&utm_medium=homeinapp', '_blank');
    mixpanel?.track('Read_help_desk');
    if (!gaFour.synctrack) {
      handleGa4(Ga4Event.SynctrackBannerClick);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!gaFour.homePage) {
            dispatch(
              sessionSlice.actions.handleGaFour({
                key: 'homePage',
              }),
            );
            handleGa4(Ga4Event.CheckoutRulesImpression);
            handleGa4(Ga4Event.SynctrackBannerImpression);
            handleGa4(Ga4Event.ReturnImpression);
            handleGa4(Ga4Event.ImpressOT);
            handleGa4(Ga4Event.ImpressEstimated);
            handleGa4(Ga4Event.ImpressCookies);
          }
        }
      });
    });

    if (recommendAppRef.current) {
      observer.observe(recommendAppRef.current);
    }

    // Cleanup observer khi component unmount
    return () => {
      if (recommendAppRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(recommendAppRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gaFour.homePage]);

  return (
    <HomePageStyled>
      <CustomLayout
        layoutProps={{
          title: `Hello ${dataSettings?.settings?.user?.businessName || config.shop.split('.')[0]},`,
        }}
        isVisibleHeader={!dataSettings?.url}
      >
        <Text variant="bodyLg" as="span">
          Welcome to Blockify 🎉
        </Text>
        {checkEmbeddedStatus.data && (
          <Collapsible
            id="banner-check-embedded-app"
            open={bannerNoticeEmbeddedApp && !checkEmbeddedStatus.data?.status}
            transition={{ duration: '200ms', timingFunction: 'ease-in-out' }}
          >
            <div className="mt-16">
              <Banner
                onDismiss={() => {
                  dispatch(bannerSlice.actions.handleBannernoticeEmbeddedApp(false));
                }}
                tone="warning"
                title="Action required: Activate app embed"
              >
                <div className="d-flex flex-column">
                  <RegularText>
                    Go to <b>Shopify Theme Editor</b> &gt; Turn on <b>Blockify app</b> on the App Embeds section.
                  </RegularText>
                  <div className="mt-8">
                    <Button target="_blank" url={link.isOpenAppEmbedUrl}>
                      Activate app now
                    </Button>
                  </div>
                </div>
              </Banner>
            </div>
          </Collapsible>
        )}

        <BannerReviewApp />

        <Collapsible
          id="banner-check-free-plan"
          open={userPlanFree && bannerNoticeFreePlan}
          transition={{ duration: '200ms', timingFunction: 'ease-in-out' }}
        >
          <div className="mt-16">
            <BannerLimitRules
              isHasLink
              onDismiss={() => {
                dispatch(bannerSlice.actions.handleBannernoticeFreePlan(false));
              }}
              mixpanelName="Home_increase_limit"
            />
          </div>
        </Collapsible>

        <div className="mt-16">
          <Overview />
        </div>

        <div className="mt-16">
          <Collapsible
            id="gift_box"
            open={isShowBannerCampaign}
            transition={{ duration: '200ms', timingFunction: 'ease-in-out' }}
          >
            <FlashSaleCard />
          </Collapsible>
        </div>

        <div className="card-info">
          <InlineGrid columns={{ xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }} gap={'400'}>
            {infoAndSupport.map((item, index) => {
              return (
                <Card key={index}>
                  <div className="card-container">
                    <div>
                      <div className="d-flex">
                        <Icon source={item.icon} />
                        <div className="ml-8">
                          <BoldText>{item.title}</BoldText>
                        </div>
                      </div>
                      <div className="card-text">
                        <RegularText>{item.description}</RegularText>
                      </div>
                    </div>
                    <div className="mt-8">
                      <Button onClick={item.onAction}>{item.btnContent}</Button>
                    </div>
                  </div>
                </Card>
              );
            })}
          </InlineGrid>
        </div>
        {/* Checkout rules */}
        <div className="mt-16">
          <BannerTemplate src={dashboardImage.checkoutRules}>
            <BoldText>Prevent failed deliveries & Tailor shipping options that your customers see.</BoldText>
            <div className="mt-8">
              <RegularText>
                Blockify Checkout Rules effortlessly validate address & checkout info to fix potential shipping issues. Hide or
                reorder shipping/payment methods to for a smooth & cost-effective experience . Get the all-in-one checkout
                solution today!
              </RegularText>
            </div>
            <div className="mt-16">
              <Button
                onClick={() => {
                  if (!gaFour.checkoutRules) {
                    handleGa4(Ga4Event.CheckoutRulesClick);
                  }

                  window.open(
                    'https://apps.shopify.com/blockify-checkout-rules?utm_campaign=crs&utm_source=blockify&utm_medium=homeinapp',
                    '_blank',
                  );
                }}
                variant="primary"
              >
                Try now
              </Button>
            </div>
          </BannerTemplate>
        </div>
        {/* Synctrack */}
        <div className="mt-16">
          <BannerTemplate src={promote} isSynctrackBanner>
            <BoldText>
              Protect your cash flow. Sync tracking info to PayPal & Stripe for faster funds release and build trust
            </BoldText>
            <div className="mt-8">
              <RegularText>
                Synctrack adds tracking info to PayPal & Stripe in real-time. It helps you save valuable time, avoid disputes,
                money on hold, PayPal limits, and rolling reserves. Keeps your business transparent & stay away from disputes!
              </RegularText>
            </div>
            <div className="mt-16">
              <Button onClick={handleLearnMoreSynctrack} variant="primary">
                Try for free
              </Button>
            </div>
          </BannerTemplate>
        </div>

        <div className="app-recommended" ref={recommendAppRef}>
          <RecommendedApps />
        </div>
      </CustomLayout>
    </HomePageStyled>
  );
}

export default memo(HomePage);
